export const hero = {
  title: "Take control with best-in-class enterprise point-of-sale",
  subTitle: "Enterprise point-of-sale",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/pos-terminals/demo",
  },
};

export const heroImage = {
  imageName: "products-pos-terminals-hero.png",
};

export const secondaryHeroTwoCols = {
  title: "Sleek hardware configurations built to last",
  subtext: `Increase transparency and order accuracy with customer-facing displays, enable on-screen signatures and tipping, and create revenue streams with sponsorship opportunities. Fixed and portable options are available in multiple screen sizes, including 10″, 15″, and 22″.`,
  image: {
    imageName: "products-pos-terminals-secondary-hero-2.png",
    mobileImageName: "products-pos-terminals-secondary-hero-2-mobile.png",
    title: "We’re dedicated to your success",
    subtext: "POINT OF SALE TERMINALS",
  },
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/pos-terminals/demo",
  },
};

export const simpleText = {
  title: "Give guests peace of mind while boosting your bottom line",
  subtext:
    "Engage guests throughout the sale with customer-facing displays that increase order accuracy and provide digital tips and signature options. Earn additional revenue from your vendors with a new advertising platform.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/pos-terminals/demo",
  },
};

export const secondaryHeroTitle = {
  title: "Redefine business operations with modern enterprise POS software",
  subtext:
    "Secure, cloud-based POS software that speeds up service and scales with your business.",
  imageName: "products-pos-terminals-secondary-hero-1.png",
  mobileImageName: "products-pos-terminals-secondary-hero-1-mobile.png",
};

export const productOverview = {
  subSections: [
    {
      title: "Smart POS software built for your business",
      bullets: [
        "Quick Service for fast-paced, high-volume enterprises",
        "Table Service for restaurant and dining establishments",
        "Retail for merchandise, gift shops, and other retail businesses",
      ],
      imageName: "products-pos-terminals-product-1.png",
    },
    {
      title: `Easy-to-use <br /> image-driven interface`,
      subtext:
        "Improve staff performance with intuitive interfaces that guide them through the checkout experience. Our software is built for simplicity, reduces training times, and gets your guests out the door faster.",
      imageName: "products-pos-terminals-product-2.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/pos-terminals/demo",
      },
    },
    {
      title: "Intelligent software designed for maximum efficiency",
      subtext:
        "From embedded upsells to automatic discounts and promotions, we’ve created a seamless checkout experience for both the cashier and guest.",
      imageName: "products-pos-terminals-product-3.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/pos-terminals/demo",
      },
    },
  ],
};

export const highlightWithImage = {
  title: "Accept the latest and most secure ways to pay",
  subtext:
    "Accelerate transaction times by accepting all popular payment types, including cash, credit, EMV, RFID, NFC, and custom tenders. With loaded value, loyalty, member benefits, and stored value integrations, you can do business your way.",
  imageName: "products-pos-terminals-product-4.png",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/pos-terminals/demo",
  },
};

export const productSlides = {
  title: "An inside look at your new POS system",
  slides: [
    {
      title: "Easily view & add items to cart",
      subtext:
        "Place orders and add modifiers quickly and easily with our intuitive workflow. Clear item descriptions and allergy information to quickly answer guest questions on the spot. Item pairs and upsell prompts increase check values and give guests more of what they want.",
      imageName: "products-pos-terminals-slides-1.png",
    },
    {
      title: "Automatically or manually apply discounts & promotions",
      subtext:
        "Assign discounts and promotions on both the item and cart level. Staff can also manually enter promo codes at checkout, pre-build promotion templates, including BOGO triggers/happy hour scheduling, or tender-specific discounts.",
      imageName: "products-pos-terminals-slides-2.png",
    },
    {
      title: "Select payment type",
      subtext:
        "Select the payment method and check guests out in a few clicks. Speed up transactions with technology that presents the most likely cash combo. Quickly divide up bills with split check functionality.",
      imageName: "products-pos-terminals-slides-3.png",
    },
    {
      title: "Digital gratuities & receipts",
      subtext:
        "Enable guests to tip and select electronic or printed receipts via the customer-facing display.",
      imageName: "products-pos-terminals-slides-4.png",
    },
  ],
};

export const productsWithLinks = {
  title: "A true omnichannel POS product suite",
  products: [
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform the guest experience, provide valuable business insights, and deliver ROI to your enterprise.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/pos-terminals/demo",
  },
};
