import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  hero,
  heroImage,
  secondaryHeroTwoCols,
  simpleText,
  secondaryHeroTitle,
  productOverview,
  highlightWithImage,
  productSlides,
  productsWithLinks,
  footer,
} from "../../../data/appetize/pos-terminals";
import { enterpriseSupport } from "../../../data/spoton-contacts";

const SimpleHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/SimpleHero")
);
const LargeImageFullWidth = loadable(() =>
  import("../../../components-v2/Sections/LargeImageFullWidth")
);
const SecondaryHeroTitleTwoCols = loadable(() =>
  import("../../../components-v2/Sections/Hero/SecondaryHeroTitleTwoCols")
);
const SimpleTitleWithDescription = loadable(() =>
  import("../../../components-v2/Sections/SimpleTitleWithDescription")
);
const SecondaryHeroTitle = loadable(() =>
  import("../../../components-v2/Sections/Hero/SecondaryHeroTitle")
);
const ProductOverview = loadable(() =>
  import("../../../components-v2/Sections/ProductOverview")
);
const ProductSlides = loadable(() =>
  import("../../../components-v2/Sections/ProductSlides")
);
const ProductsWithLinksPlainBackground = loadable(() =>
  import("../../../components-v2/Sections/ProductsWithLinksPlainBackground")
);
const FooterHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/FooterHero")
);
const HighlightWithImageAndOffsetText = loadable(() =>
  import("../../../components-v2/Sections/HighlightWithImageAndOffsetText")
);

const PosTerminals = () => {
  return (
    <Layout className="non-fixed-width" {...enterpriseSupport}>
      <SEO
        loadChat={false}
        title="Enterprise POS Terminals | SpotOn"
        description={hero.title}
        cannonical="https://www.spoton.com/enterprise/pos-terminals/"
      />
      <SimpleHero
        className="mt-32 lg:mt-40"
        {...hero}
        titleCustomClass="xl:max-w-80-percent"
      />

      <LargeImageFullWidth className="mt-16 lg:mt-20" {...heroImage} />

      <SecondaryHeroTitle
        className="mt-20 lg:mt-36"
        {...secondaryHeroTitle}
        mobileImageObjectPosition={5}
      />

      <ProductOverview
        className="mt-20 lg:mt-36"
        {...productOverview}
        noMainTitle
        offsetText
      />

      <HighlightWithImageAndOffsetText
        className="mt-20 lg:mt-40"
        {...highlightWithImage}
      />

      <ProductSlides className="mt-20 lg:mt-36" {...productSlides} />

      <SimpleTitleWithDescription className="mt-20 lg:mt-36" {...simpleText} />

      <SecondaryHeroTitleTwoCols
        className="mt-20 lg:mt-36"
        {...secondaryHeroTwoCols}
      />

      <ProductsWithLinksPlainBackground
        className="mt-20 lg:mt-36"
        {...productsWithLinks}
      />

      <FooterHero className="my-20 lg:my-36" {...footer} />
    </Layout>
  );
};

export default PosTerminals;
